.ChatForm {
    margin-left: 25%;
    margin-right: 25%;
    color: white;
    text-align: justify;
}

.chat-text {
    padding-top: 10px;
    font-size: 16pt;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #EEEEEE;
}

form {
    -webkit-appearance: none;
}

.form-input {
    margin-top: 20px;
    background: #FFA4A4;
    color: #ffffff;
    width: 100%;
    display: block;
    height: 40px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    font-weight: bold;
    box-sizing: border-box;
    border: 0;
    border-radius: 5px;
}

.form-input::placeholder {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
    opacity: 0.5;
}

.form-input:focus {
    outline: none;
}

.form-input[type=text] {
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
}

.btn-submit {
    margin-top: 20px;
    box-shadow: -5px 5px 10px rgba(230, 96, 89, 0.2), 5px -5px 10px rgba(230, 96, 89, 0.2), -5px -5px 10px rgba(255, 118, 109, 0.9), 5px 5px 13px rgba(230, 96, 89, 0.9), inset 1px 1px 2px rgba(255, 118, 109, 0.3), inset -1px -1px 2px rgba(230, 96, 89, 0.5);
}

.btn-giftgpt {
    margin-top: 20px;
    background: #FF817A;
    box-shadow: -5px 5px 10px rgba(230, 96, 89, 0.2), 5px -5px 10px rgba(230, 96, 89, 0.2), -5px -5px 10px rgba(255, 118, 109, 0.9), 5px 5px 13px rgba(230, 96, 89, 0.9), inset 1px 1px 2px rgba(255, 118, 109, 0.3), inset -1px -1px 2px rgba(230, 96, 89, 0.5);
}

.btn {
    font-weight: bold;
    font-size: 12pt;
    color: white;
    background-color: #FF7972;
    width: 100%;
    border: 0;
    height: 40px;
    transition: 0.5s;
}

.btn:hover {
    cursor: pointer;
    transform: translate(-5px, -5px);
    box-shadow: -5px 15px 15px rgba(230, 96, 89, 0.2), 5px -5px 10px rgba(230, 96, 89, 0.2), -5px -5px 10px rgba(255, 118, 109, 0.9), 5px 5px 13px rgba(230, 96, 89, 0.9), inset 1px 1px 2px rgba(255, 118, 109, 0.3), inset -1px -1px 2px rgba(230, 96, 89, 0.5);
    transition: 0.5s;
}

.btn:active {
    cursor: pointer;
    transform: translate(5px, 3px);
    box-shadow: -5px 5px 5px rgba(230, 96, 89, 0.2), 5px -5px 10px rgba(230, 96, 89, 0.2), -5px -5px 10px rgba(255, 118, 109, 0.9), 5px 5px 13px rgba(230, 96, 89, 0.9), inset 1px 1px 2px rgba(255, 118, 109, 0.3), inset -1px -1px 2px rgba(230, 96, 89, 0.5);
    transition: 0.1s;
}




.ChatOutput {
    min-height: 150px;
    overflow: auto;
    margin-top: 20px;
    border-radius: 10px;
    background: #FF6158;
    box-shadow: -5px 5px 10px rgba(230, 96, 89, 0.2), 5px -5px 10px rgba(230, 96, 89, 0.2), -5px -5px 10px rgba(255, 118, 109, 0.9), 5px 5px 13px rgba(230, 96, 89, 0.9), inset 1px 1px 2px rgba(255, 118, 109, 0.3), inset -1px -1px 2px rgba(230, 96, 89, 0.5);
}

.poemOutput {
    white-space: pre-line;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
    padding: 20px;
}

@media (max-width:1200px) {
    .ChatOutput {
        margin-left: 0%;
        margin-right: 0%;
    }

    .ChatForm {
        margin-left: 10%;
        margin-right: 10%;
        text-align: center;
    }
}

.header-text {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: #FFFFFF;
}

.subheader-text {
    padding-top: 25px;
    padding-bottom: 20px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #FAFAFA;
}
.count-text{
    font-weight: bold;
    padding-bottom: 20px;
    font-family: 'Nunito';
    font-style: normal;
    opacity:0.5;
    font-size: 16px;
    text-align: center;
    color: #FAFAFA;
}
.link {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
}

.link-footer {
    text-decoration: none;
    color: black;
    text-decoration: underline;
}


.suggested-questions {
    padding-top: 10px;
    display: flex;
    overflow-x: auto;
    width: 100%;
}

.suggested-questions::-webkit-scrollbar {
    display: none;
}

.suggested-questions {
    -ms-overflow-style: none;
}

.suggested-questions {
    scrollbar-width: none;
}

.suggested-question-text {
    margin-right: 15px;
    white-space: nowrap;
    background-color: #FF574E;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
}

#copyBtn {
    padding: 5px;
    height: 25px;
    width: 25px;
    float: right;
}

#copyBtn:hover {
    cursor: pointer;
}

.footer {
    text-align: center;
    margin-top: 20px;
    font-size: 12pt;
    font-weight: bold;
}