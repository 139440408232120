
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:wght@100;400;500;600;700&display=swap');

html {
  height: 100%;
  
}

.App {
  
  margin: 0;
  padding: 0;
}
body{
  background-color: #E58381;
  font-family: 'Nunito';
  background: radial-gradient(50% 50% at 50% 50%, #FF6961 0%, #FF8C86 100%);

  min-height:100vh;

  /* font-family: 'Cinzel', serif;
  font-family: 'Josefin Sans', sans-serif;
  font-family: 'Montserrat', sans-serif; */
}

.Header{
  padding-top:5%;
  text-align:center;
  color:white;
}
